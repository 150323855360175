.rowAlignMent {
  display: flex;
  align-items: center;
  background-color: #a5a5a5;
}
.rowIconStyle {
  width: 40%;
}
.secondColAlign {
  display: flex;
  flex-direction: column;
  text-align: right;
}
