.aboutWrraper {
  padding-top: 20vh;
}
.aboutWrraper h1 {
  color: white;
  text-align: center;
  font-size: 3vw;
  font-weight: 800;
}
.aboutWrraper h4 {
  color: white;
  text-align: center;
  font-size: 1.4vw;
  margin-top: 3.5vh;
}
.colImage {
  width: 80%;
  border-radius: 14px;
}
.rowCardDesign {
  display: flex;
  align-items: center;
  padding-top: 80px;
}
.colTitle {
  color: white;
  font-size: 2vw;
  font-weight: 700;
}
.colText {
  color: white;
  font-size: 1.2vw;
  margin-top: 1.3vh;
}

@media screen and (max-width: 991px) {
  .aboutWrraper h1 {
    font-size: 4vw;
  }
  .aboutWrraper h4 {
    font-size: 2vw;
  }
  .rowCardDesign {
    padding-top: 40px;
  }
  .colTitle {
    font-size: 2.5vw;
  }
  .colText {
    font-size: 1.6vw;
  }
}
@media screen and (max-width: 768px) {
  .aboutWrraper h1 {
    font-size: 4.5vw;
  }
  .aboutWrraper h4 {
    font-size: 2.5vw;
  }
  .rowCardDesign {
    padding-top: 20px;
  }
  .colImage {
    width: 50%;
    margin: auto;
    align-items: center;
    display: flex;
  }
  .colTitle {
    font-size: 3vw;
  }
  .colText {
    font-size: 2vw;
  }
}
@media screen and (max-width: 767px) {
  .firstOrder {
    order: 2;
  }
  .secondOrder {
    order: 1;
  }
  .rowCardDesign {
    text-align: center;
  }
}
@media screen and (max-width: 576px) {
  .aboutWrraper h1 {
    font-size: 6vw;
  }
  .aboutWrraper h4 {
    font-size: 3.5vw;
  }
  .colTitle {
    font-size: 5vw;
  }
  .colText {
    font-size: 3.5vw;
  }
}
