.blogWrraper {
  padding-top: 20vh;
}
.blogWrraper h1 {
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 3.2vw;
}
.blogPageImage {
  width: 100%;
  margin-top: 10vh;
}

.blogWrraper h3 {
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 1.6vw;
  margin-top: 4vh;
}
.blogWrraper h4 {
  color: white;
  font-weight: 700;
  font-size: 1.4vw;
  margin-top: 5vh;
}
@media screen and (max-width: 991px) {
  .blogWrraper h1 {
    font-size: 4vw;
  }
  .blogWrraper h3 {
    font-size: 2.3vw;
  }
  .blogWrraper h4 {
    font-size: 2vw;
  }
}
@media screen and (max-width: 768px) {
  .blogWrraper h1 {
    font-size: 4.5vw;
  }
  .blogWrraper h3 {
    font-size: 2.9vw;
  }
  .blogWrraper h4 {
    font-size: 2.5vw;
  }
}
@media screen and (max-width: 576px) {
  .blogWrraper h1 {
    font-size: 5.5vw;
  }
  .blogWrraper h3 {
    font-size: 4vw;
  }
  .blogWrraper h4 {
    font-size: 3vw;
  }
}
