body {
  background-color: #0e0e0e !important;
}
.headerWrraper {
  position: fixed !important;
  width: 100%;
  z-index: 1000;
  background-color: #0e0e0e;
  padding: 2.5vh 0 !important;
  position: fixed;
}
.headerRouterDiv {
  gap: 20px;
}
.introHeadingDiv {
  padding-top: 100px;
}

.heroWrraper {
  display: flex;
  align-items: center;
  padding-top: 20vh !important;
}
.heroText {
  color: white;
}
.heroText {
  font-family: Open Sans;
  font-weight: 700;
  font-size: 64px;
  -webkit-text-stroke: 1px #6641fe;
}
.heroText span {
  color: #6641fe !important;
  /* font-size: 3.5vw; */
}
.heroWrraper span {
  color: white;
  font-family: Open Sans;
  font-weight: 700;
  font-size: 64px !important;
  -webkit-text-stroke: 1px #6641fe;
}
.exploreBtn {
  display: block;
  padding: 10px 20px;
  color: white;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 20px;
  border: 1px solid #6641fe;
  background-color: transparent;
  margin-top: 63px;
  border-radius: 28px;
  transition: transform 0.3s;
}
.exploreBtn:hover {
  transform: scale(1.05);
  background: radial-gradient(
    100% 1151.15% at 0% 50%,
    #d52d6a 0%,
    #8d2e8f 100%
  );
  border-color: white;
}
.circle1 {
  width: 250px;
  height: 250px;
  border: 2px solid #53b057;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 2;
}
.circle2 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fcd5f0;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 1;
  margin-left: -50px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;

  text-align: center;
}
.circle3 {
  width: 180px;
  height: 180px;
  border: 2px solid #53b057;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 3;
}
.circle4 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #d2e9ff;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 4;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
}
.circle5 {
  width: 250px;
  height: 250px;
  border: 2px solid #53b057;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 1;
}
.circle6 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fcd5f0;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 2;
  margin-left: -50px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
}
.circle7 {
  width: 180px;
  height: 180px;
  border: 2px solid #53b057;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 3;
}
.circle8 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #d2e9ff;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 4;
}
.circle9 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #d2e9ff;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 5;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
}
.bigCircleText {
  font-family: Open Sans;
  font-size: 54px;
  font-weight: 600;
  line-height: 87px;
  text-align: center;
  color: white;
}
.bigCircleText2 {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff80;
}
.midCircleText {
  font-family: Open Sans;
  font-size: 30px;
  font-weight: 600;
  line-height: 46px;
  text-align: center;
  color: white;
}
.midCircleText2 {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
  color: #ffffff80;
}
.sectionHeader {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 48px;
  border-left: 10px solid blue;
  color: white;
  padding-left: 20px;
}
.SectionTitle {
  font-family: Open Sans;
  font-size: 34px;
  font-weight: 600;
  line-height: 46px;
  text-align: center;
  color: white;
  margin-top: 70px;
}
.whoweImageDiv {
  width: 300px;
  height: 300px;
}
.whoweImage {
  width: 100%;
  height: 100%;
}
.whoWeSectionRow {
  display: flex;
  /* align-items: center; */
  margin-top: 30px !important;
}
.sectionText {
  font-family: Open Sans;
  font-size: 32px;
  font-weight: 400;
  line-height: 44px;
  color: #f2f2f280;
  margin-top: 60px;
}
.aboutText {
  font-family: Open Sans;
  font-size: 22px;
  font-weight: 400;
  color: #f2f2f280;
}
.eclipseDiv {
  width: 100%;
  margin-top: 120px;
}
.eclipseImage {
  width: 100%;
}
.rectangularShape {
  background-color: #d9d9d9;
  width: 70px;
  height: 70px;
  /* margin-left: 52%; */
}
.serviceSection {
  background-color: #111112;
  margin-top: -50px;
}
.serviceSectionHeader {
  font-family: Open Sans;
  font-weight: 600;
  font-size: 48px;
  border-left: 10px solid blue;
  color: white;
  padding-left: 20px;
  /* padding-top: 50px; */
}

.colCardText {
  font-family: Open Sans;
  font-size: 21px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.02em;
  /* text-align: center; */
  color: #f2f2f280;
  margin-top: 30px;
}

.cardIconStyle {
  width: 60px;
  height: 60px;
}
.cardRowWrraper {
  margin-top: 100px !important;
}
.colCardWrraper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #13436c;
  border-radius: 20px;
  padding: 40px;
  height: 100%;
  justify-content: space-evenly;
  transition: transform 0.3s;
}

.colCardWrraper:hover {
  transform: scale(1.05);
  background-color: #1a59cf;
  /* background: radial-gradient(
    100% 1151.15% at 0% 50%,
    #d52d6a 0%,
    #8d2e8f 100%
  ); */
}

.colCardTitle {
  color: #fff;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0.02em;
  /* text-align: center; */
  margin-top: 20px;
}
.knowMoreBtn {
  color: white;
  /* text-align: center; */
  margin-top: 30px;
  font-family: Open Sans;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  margin: 30px 30% 0px;
}
.knowMoreBtn2 {
  color: white;
  /* text-align: center; */
  margin-top: 30px;
  font-family: Open Sans;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  margin: 30px 30% 0px;
}
.knowMoreBtn3 {
  color: white;
  /* text-align: center; */
  margin-top: 30px;
  font-family: Open Sans;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  margin: 30px 30% 0px;
}
.knowMoreBtn4 {
  color: white;
  /* text-align: center; */
  margin-top: 30px;
  font-family: Open Sans;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  margin: 30px 30% 0px;
}
.knowMoreBtn5 {
  color: white;
  /* text-align: center; */
  margin-top: 30px;
  font-family: Open Sans;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  margin: 30px 30% 0px;
}
.knowMoreBtn6 {
  color: white;
  /* text-align: center; */
  margin-top: 30px;
  font-family: Open Sans;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  margin: 30px 30% 0px;
}
.cardImage {
  width: 100%;
  height: 100%;
}
.servicesBtn {
  border: 1px solid #6641fe;
  border-radius: 64px;
  background-color: transparent;
  color: white;
  font-family: Open Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  padding: 15px 120px;
  margin-left: 25px;
}
.serviceBtnWrraper {
  margin-top: 100px;
  text-align: center;
  padding-bottom: 100px;
}
.textCenter {
  text-align: center;
}
.showcaseProjectBtn {
  border: 1px solid #6641fe;
  border-radius: 64px;
  background-color: transparent;
  color: white;
  font-family: Open Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  padding: 15px 90px;
  margin-top: 80px;
  display: flex;
  margin: 80px auto;
  transition: trasform 0.3s;
}

.showcaseProjectBtn:hover {
  transform: scale(1.05);
  background: radial-gradient(
    100% 1151.15% at 0% 50%,
    #d52d6a 0%,
    #8d2e8f 100%
  );
  border-color: white;
}

.aboutProjectDiv {
  padding: 70px 80px;
  border: 1px solid #6641fe;
  display: flex;
  align-items: center;
}
.aboutProjectText {
  font-family: Open Sans;
  font-size: 56px;
  font-weight: 700;
  line-height: 76px;
  text-align: left;
  color: #f2f2f2;
}
.getInTouchBtn {
  background: radial-gradient(
    100% 1151.15% at 0% 50%,
    #d52d6a 0%,
    #8d2e8f 100%
  );
  color: white;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
  border: none;
  padding: 10px 30px;
  border-radius: 28px;
  transition: transform 0.5s;
}
.getInTouchBtn:hover {
  border-color: white;
  transform: scale(1.05);
}
.getInbtnColWrraper {
  display: flex;
  justify-content: flex-end;
}
.voiceOfSatisfactionwrraper {
  padding-bottom: 50px;
}
.voiceofSatisfactionSection {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
}
.voiceofSatisfactionSubSection {
  display: flex;
  align-items: center;
}
.mainReviewWrraper {
  display: flex;
  align-items: center;
  margin-top: 20px !important;
}
.clientReviewWrraper {
  display: flex;
}
.clientName {
  font-family: Open Sans;
  font-size: 30px;
  font-weight: 600;
  line-height: 41px;
  color: white;
}
.clientPosition {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  color: #f2f2f280;
  margin-top: 5px;
}
.clientReview {
  font-family: Open Sans;
  font-size: 28px;
  font-weight: 400;
  line-height: 38px;
  color: #f2f2f299;
}
.blogText {
  font-family: Open Sans;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  color: #f2f2f280;
}
.blogRowWrraper {
  margin-top: 30px !important;
  display: flex;
  align-items: center;
}
.blogTitle {
  font-family: Open Sans;
  font-size: 26px;
  font-weight: 600;
  line-height: 35px;
  color: #f2f2f2;
  margin-top: 30px;
}
.readMoreBtn {
  border-bottom: 1px solid #6641fe;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: white;
  background-color: transparent;
  border: none;
  margin-top: 30px;
  justify-self: center;
  display: flex;
  margin: 15px auto;
  border-bottom: 1px solid #6641fe;
}
.blogImageContainer {
  /* width: 100%; */
}
.blogImageStyle {
  width: 100%;
}
.joinTealTitle {
  font-family: Open Sans;
  font-size: 48px;
  font-weight: 700;
  line-height: 65px;
  color: white;
  /* margin-top: 100px; */
}
.joinTealTitle span {
  color: blue;
}
.serviceBottom {
  padding-bottom: 50px;
}
.joinTealText {
  font-family: Open Sans;
  font-size: 28px;
  font-weight: 400;
  line-height: 38px;
  color: #f2f2f280;
  text-align: center;
  margin-top: 30px;
}
.joinTheTeamBtn {
  border: 1px solid #6641fe;
  background-color: transparent;
  padding: 20px 40px;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  color: white;
  border-radius: 56px;
  display: flex;
  margin: 20px auto;
  margin-top: 50px;
  transition: transform 0.5s;
}
.joinTheTeamBtn:hover {
  transform: scale(1.05);
  background: radial-gradient(
    100% 1151.15% at 0% 50%,
    #d52d6a 0%,
    #8d2e8f 100%
  );
  border-color: white;
}
.joinTeamImage {
  width: 100%;
}
.joinTeamRowWrraper {
  display: flex;
  align-items: center;
}
.faqHeader {
  margin-top: 80px;
}
.faqCollapseWrraper {
  margin-top: 60px !important;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  background-color: #111112;
  color: white;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}
.accordion-flush .accordion-item {
  border: none;
  margin-top: 30px;
}
.accordion-button::after {
  background-image: none;
}
.accordion-body {
  background-color: #111112;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #f2f2f280;
}
.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:focus {
  color: #6641fe !important;
}
.discoverMore {
  border: 1px solid #6641fe;
  padding: 20px 40px;
  background-color: transparent;
  margin-top: 50px;
  border-radius: 56px;
  color: white;
  font-family: Open Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}
/* .discoverMore:hover {
  background-color: #6641fe;
} */
.bgImage {
  background-image: url(/public/bgImage.png);
  opacity: 1;
  /* background-color: red; */
  width: 100%;
  height: 100%;
  margin-top: -20px;
}
.bgImageTitle {
  font-family: Open Sans;
  font-size: 48px;
  font-weight: 700;
  line-height: 65px;
  text-align: center;
  color: white;
  padding-top: 150px;
}
.bgImageText {
  font-family: Open Sans;
  font-size: 48px;
  font-weight: 300;
  line-height: 65px;
  text-align: center;
  color: white;
  margin-top: 30px;
  padding-bottom: 30px;
}
.formWrraperSection {
  margin-top: 100px;
  margin-bottom: 150px;
}
.formWrraper {
  border-top: 12px solid #6641fe4d;
  border-left: 12px solid #6641fe4d;
  border-bottom: 12px solid #6641fe4d;
  padding: 40px !important;
}
.formTitle {
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 300;
  line-height: 54px;
  text-align: center;
  color: white;
}
.formInput {
  display: flex;
  justify-content: space-between;
}
.formArea {
  margin-top: 150px;
}
.form-label {
  color: #f2f2f280;
}
.submitBtn {
  /* margin-top: 50px; */
  border: 2px solid #6641fe;
  border-radius: 56px;
  display: flex;
  margin: 50px auto;
  padding: 10px 40px;
  background-color: transparent;
  color: white;
  transition: transform 0.5s;
}
.submitBtn:hover {
  transform: scale(1.05);
  background: radial-gradient(
    100% 1151.15% at 0% 50%,
    #d52d6a 0%,
    #8d2e8f 100%
  );
  border-color: white;
}

#exampleInputEmail1 {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #f2f2f280;
}
#floatingTextarea2 {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #f2f2f280;
}
.footerSectionTitle {
  font-family: Open Sans;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  color: #f2f2f2;
}
.footerText {
  font-family: Open Sans;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  color: #f2f2f2;
  margin-top: 20px;
}
.linkText {
  margin-top: 10px;
  color: #f2f2f2;
  font-family: Open Sans;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  cursor: pointer;
}
.footerSecondRowWrraper {
  padding-top: 50px;
  display: flex;
  align-items: center;
}
.footerSecondRowTitle {
  font-family: Open Sans;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  color: #f2f2f280;
  text-align: center;
}
.companyDetails {
  font-family: Open Sans;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  color: #f2f2f2;
  margin-top: 10px;
}
.locationText {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #f2f2f2;
  margin-left: 10px;
  text-align: center;
}
.socialMediaLinks {
  display: flex;
  justify-content: space-evenly;
  /* margin-top: 20px; */
  gap: 20px;
}
.socialMediaLinks img {
  cursor: pointer;
}
.locationWrraper {
  display: flex;
  justify-content: center;
}
.locationMainWrraper {
  text-align: center;
  display: flex;
  margin: auto;
  flex-direction: column;
}
.footercopyright {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #a5a5a5;
  margin-top: 25px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.365);
  padding-top: 25px;
}
.lastFooterSection {
  margin-top: unset;
  padding-top: 50px;
}

.logoImage {
  width: 10vw;
}
.FooterImage {
  width: 16vw;
  margin-bottom: 30px;
}

.heroImage {
  width: 35vw;
}

.footerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerDiv h6 {
  color: #a5a5b5;
  margin-bottom: 20px;
}
.companyDetailsDiv {
  width: 100%;
  padding-top: 20px;
}

.companyDetailsDiv h5,
.locationText {
  color: #a5a5a5;
  font-size: 18px;
}
.footerLinks {
  display: flex;
  gap: 3vw;
  color: #a5a5a5;
  font-size: 18pxs;
}
.introDivDiv h1 {
  color: white;
  text-align: center;
  font-size: 3vw;
  font-weight: 800;
}
.introDivDiv h4 {
  color: white;
  text-align: center;
  font-size: 1.4vw;
  margin-top: 5.5vh;
}
.pageIntroDiv {
  /* padding-top: 100px; */
}

/* .heroSection {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .servicesWrraper {
  background-color: yellow !important;
  height: 100vh !important;
  width: 100% !important;
} */

@media screen and (max-width: 1440px) {
  .heroText {
    font-size: 50px;
  }
  .heroText span {
    font-size: 50px;
  }
  .heroWrraper span {
    font-size: 50px !important;
  }
}

@media screen and (max-width: 991px) {
  .blogText,
  .blogTitle,
  .joinTealText {
    font-size: 22px;
  }
  .pageIntroDiv {
    padding-top: unset;
  }
  .joinTealTitle {
    font-size: 36px;
    line-height: unset;
  }
  .joinTheTeamBtn {
    font-size: 14px;
  }
  .rectangularShape {
    width: 40px;
    height: 40px;
  }
  .sectionHeader {
    font-size: 30px;
  }
  .SectionTitle {
    font-size: 24px;
  }
  .formTitle {
    font-size: 34px;
  }
  .bgImageTitle,
  .bgImageText {
    font-size: 38px;
  }
  .clientReview {
    font-size: 20px;
  }
  .clientName {
    font-size: 22px;
    line-height: unset;
  }
  .clientPosition {
    font-size: 18px;
  }
  .aboutProjectText {
    font-size: 44px;
  }
  .showcaseProjectBtn {
    font-size: 18px;
  }
  .heroImage {
    width: 65vw;
  }
  .exploreBtn {
    font-size: 18px;
  }
  .logoImage {
    width: 17vw;
  }
  .heroSection {
    text-align: center;
  }
  .exploreBtn {
    margin: 30px auto;
  }
  .blogDivWrraper {
    text-align: center;
    padding-top: 30px;
  }
  .blogDivWrraper div {
    margin-top: 30px;
  }
  .reviewWrraperDiv {
    text-align: center !important;
    align-items: center;
  }
  .FooterImage {
    width: 20vw;
  }
  .introDivDiv h1 {
    font-size: 4vw;
  }
  .introDivDiv h4 {
    font-size: 2vw;
  }
  .introHeadingDiv {
    padding-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .introDivDiv h1 {
    font-size: 4.5vw;
  }
  .introDivDiv h4 {
    font-size: 2.5vw;
  }
  .rectangularShape {
    width: 25px;
    height: 25px;
  }
  .heroText {
    font-size: 40px;
  }
  .heroWrraper span {
    font-size: 40px !important;
  }
  .serviceSectionHeader {
    font-size: 30px;
  }
  .aboutProjectText {
    font-size: 36px;
    line-height: unset;
    text-align: center;
    margin-bottom: 20px;
  }
  .getInbtnColWrraper {
    justify-content: center;
  }
  .firstOrder {
    order: 2;
  }
  .secondOrder {
    order: 1;
  }
  .colCardWrraper {
    padding: 20px;
  }
  .voiceSectionImage,
  .joinTeamImage,
  .businessImage {
    display: none;
  }
  .logoImage {
    width: 20vw;
  }
  .blogImageStyle {
    width: 90%;
  }
  .footerDiv h6 {
    text-align: center;
  }
  .companyDetailsDiv h5,
  .locationText {
    color: #a5a5a5;
    font-size: 12px;
  }
  .footercopyright {
    font-size: 16px;
  }
  .footerLinks a {
    font-size: 12px;
  }
  .qoute {
    display: none;
  }
  .FooterImage {
    width: 23vw;
  }
}
@media screen and (max-width: 576px) {
  .introDivDiv h1 {
    font-size: 6vw;
  }
  .introDivDiv h4 {
    font-size: 3.5vw;
  }
  .heroText {
    font-size: 30px;
  }
  .heroWrraper span {
    font-size: 30px !important;
  }
  .exploreBtn,
  .showcaseProjectBtn {
    font-size: 14px;
  }
  .serviceSectionHeader {
    font-size: 24px;
  }
  .colCardTitle {
    font-size: 20px;
  }
  .colCardText,
  .knowMoreBtn {
    font-size: 18px;
  }
  .sectionHeader {
    font-size: 24px;
  }
  .sectionText {
    font-size: 20px;
    line-height: unset;
  }
  .aboutProjectText {
    font-size: 24px;
  }
  .getInTouchBtn {
    font-size: 18px;
  }
  .footerDiv h5 {
    font-size: 12px;
  }
  .footercopyright {
    font-size: 14px;
  }
  .companyDetailsDiv h4,
  .locationText {
    font-size: 14px !important;
  }
  .joinTealTitle {
    font-size: 30px;
    text-align: center;
  }
  .footercopyright {
    display: flex;
    flex-direction: column;
  }
  .socialMediaLinks {
    justify-content: center;
    display: flex;
    margin-top: 10px;
  }
  .FooterImage {
    width: 29vw;
  }
}
