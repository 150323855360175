.pageNotFound {
  padding-top: 20vh;
  min-height: 50vh;
}
.pageNotFound h1 {
  color: white;
  font-size: 4vw;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12vh;
}
@media screen and (max-width: 991px) {
  .pageNotFound h1 {
    font-size: 5vw;
  }
}
@media screen and (max-width: 768px) {
  .pageNotFound h1 {
    font-size: 6vw;
  }
}
@media screen and (max-width: 576px) {
  .pageNotFound h1 {
    font-size: 7vw;
  }
}
