.servicesWrraper {
  padding-top: 20vh;
}

.servicesWrraper h3 {
  color: white;
  font-size: 1.5vw;
  margin-top: 2.5vh;
}
.expertizeSection {
  margin-top: 6vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: flex-end; */
}
.footerCommanDiv {
  margin-top: 70px;
}

@media screen and (max-width: 991px) {
  .servicesWrraper h3 {
    font-size: 2.5vw;
  }
}
@media screen and (max-width: 768px) {
  .servicesWrraper h3 {
    font-size: 3.5vw;
  }
}
@media screen and (max-width: 576px) {
  .servicesWrraper h3 {
    font-size: 4vw;
  }
}
