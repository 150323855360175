.policyWrraper {
  padding-top: 20vh;
}

.policyWrraper h2 {
  text-align: center;
  color: white;
  font-size: 1.7vw;
}

.privacyIntroDiv {
  padding-top: 100px;
}

.privacyText h4 {
  color: white;
  text-align: center;
  font-size: 1.4vw;
  margin-top: 5.5vh;
}
.privacyHeader {
  font-size: 2vw;
}
.privacyIntroDiv ul li {
  color: white;
  margin-top: 30px;
  font-size: 1.4vw;
}

@media screen and (max-width: 991px) {
  .policyWrraper h2 {
    font-size: 2.2vw;
  }
  .privacyIntroDiv {
    padding-top: 30px;
  }
  .privacyIntroDiv ul li {
    font-size: 2.1vw;
  }
  .privacyIntroDiv h4 {
    font-size: 2.1vw;
  }
}
@media screen and (max-width: 768px) {
  .policyWrraper h2 {
    font-size: 2.7vw;
  }
  .privacyIntroDiv {
    padding-top: 30px;
  }
  .privacyIntroDiv ul li {
    font-size: 2.7vw;
  }
  .privacyIntroDiv h4 {
    font-size: 2.7vw;
  }
}
@media screen and (max-width: 576px) {
  .policyWrraper h2 {
    font-size: 3.5vw;
  }
  .privacyIntroDiv {
    padding-top: 30px;
  }
  .privacyIntroDiv ul li {
    font-size: 3.5vw;
  }
  .privacyIntroDiv h4 {
    font-size: 3.5vw;
  }
}
