.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-to-top.visible {
  opacity: 1;
  visibility: visible;
}
