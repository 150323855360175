.headerWrraper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  /* background-color: #0e0e0e !important; */
  /* border-bottom: 1px solid gray; */
}
.navMenu {
  width: 100%;
}
.navbarMainWrraper {
  border-bottom: 5px solid #131313;
}

/* #activeLink {
  color: #ff0000; 
  font-weight: bold; 
} */

.subHeader {
  display: flex;
  justify-content: space-between;
  /* width: 60%; */
  align-items: center;
}
.navbarWrraper li {
  /* width: 100%; */
  margin-left: 30px;
}

#navbarSupportedContent li a {
  display: flex;
  text-decoration: none;
  list-style-type: none;
  justify-content: space-between;
  font-family: Open Sans;
  font-weight: 600;
  color: white;
}
.headerBtn {
  background: radial-gradient(
    100% 1151.15% at 0% 50%,
    #d52d6a 0%,
    #8d2e8f 100%
  );
  padding: 10px 20px;
  border-radius: 28px !important;
  border: none;
  color: white;
  font-family: Open Sans !important;
  font-size: 20px;
  font-weight: 700;
  margin-left: 50px;
  width: 190px;
  height: 56px;
  transition: transform 0.5s;
}
.headerBtn:hover {
  transform: scale(1.05);
  border-color: white;
}
#navbarMenu {
  background-color: white;
}
#navbarSupportedContent {
  /* display: flex; */
  /* justify-content: space-between; */
}
#navbarSupportedContent li {
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  #navbarSupportedContent li a {
    justify-content: center;
    display: flex;
  }
  .headerBtn {
    margin-top: 30px !important;
    display: flex;
    margin-left: unset;
    margin: auto;
    align-items: center;
    justify-content: center;
  }
}
